import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/types';

import hasProduct from 'core/hasProduct';

import NewDCBOrders from './NewDCBOrders';

// Right now we are just sending notifications for new DCB orders but who knows
// what the future will have in store...
const SpringNotifications = () => {
    const { products } = useSelector((state: RootState) => state.dealer.dealerApi);
    const { dealersData } = useSelector((state: RootState) => state.iua);
    const dealerCodes = dealersData?.map(dealer => dealer.code).join();

    if (dealerCodes && hasProduct({ products, productCode: 'dcb' })) {
        return <NewDCBOrders dealerCode={dealerCodes} />;
    }
    return null;
};

export default SpringNotifications;
