import React from 'react';

import PermissionsProvider from 'components/PermissionsProvider';
import NavigationItem from 'components/Navigation/NavigationItem';
import ComposeNavigationItems from './ComposeNavigationItems';

interface Props {
    className?: string;
}

const DealerNavigationItems = React.memo(({ className }: Props) => (
    <ComposeNavigationItems origin="header">
        {({ onNavigationClick }) => (
            <React.Fragment>
                <NavigationItem id="dealer-page" to="/dealer/edit" onClick={onNavigationClick} className={className}>
                    Företagsuppgifter
                </NavigationItem>
                <NavigationItem
                    id="dealerpresentation"
                    to="/dealer/presentation"
                    onClick={onNavigationClick}
                    className={className}
                >
                    Presentation
                </NavigationItem>
                <NavigationItem id="sellers" to="/dealer/sellers" onClick={onNavigationClick} className={className}>
                    Säljare
                </NavigationItem>
                <PermissionsProvider action="update" target="finance">
                    <NavigationItem id="finance" to="/dealer/finance" onClick={onNavigationClick} className={className}>
                        Finansiering
                    </NavigationItem>
                </PermissionsProvider>
                <NavigationItem id="ad-pricing" to="/dealer/pricing" onClick={onNavigationClick} className={className}>
                    Annonspriser
                </NavigationItem>
                <NavigationItem
                    id="invoice-history"
                    to="/dealer/invoicehistory"
                    onClick={onNavigationClick}
                    className={className}
                >
                    Betalningshistorik
                </NavigationItem>
            </React.Fragment>
        )}
    </ComposeNavigationItems>
));

export default DealerNavigationItems;
