import * as React from 'react';
import { connect } from 'react-redux';
import { actions as userActions, selectors as userSelectors } from 'state/user';
import { selectors as dealerSelectors } from 'state/dealer';
import { actions as uiActions, selectors as uiSelectors, constants as uiConstants } from 'state/ui';
import { actions as generalActions } from 'state/general';

import Header from './components/Header';
import MobileHeader from './components/MobileHeader';
import SubHeader from './components/SubHeader';

interface User {
    level: number;
    login: string;
}
interface OffcanvasCollapse {
    id: string;
    show: boolean;
    isSubsectionTo?: string;
}

interface GroupMember {
    id: number;
    code: string;
}

interface StateProps {
    dealerId: number;
    dealerCode: string;
    groupMembers: [GroupMember];
    user: User;
    isAuthed: boolean;
    offcanvas: boolean;
    dealerDropdown: boolean;
    userDropdown: boolean;
    offcanvasDealerDropdown: boolean;
    offcanvasCollapse?: OffcanvasCollapse;
}

interface DispatchProps {
    displayToast: (message: string, status?: string, timeout?: number) => void;
    showOffcanvas: (show: boolean) => void;
    showDealerDropdown: (show: boolean) => void;
    showUserDropdown: (show: boolean) => void;

    changeDealer: (dealerId: number, dealers: [{ id: number; code: string }]) => void;
    showModal: (id: string, toggle, origin: string) => void;
    logoClick: () => {};
    showOffcanvasDealerDropdown: (show: boolean) => void;
    showOffcanvasCollapse: (id?: string, show?: boolean, isSubsectionTo?: string) => void;
}

type Props = StateProps & DispatchProps;

class HeaderContainer extends React.Component<Props> {
    /**
     * @param {Object} items
     * @param {String} path
     *
     * Returns subItems of item that matches path
     */
    static findSubItems(items, path) {
        const navigationItem = items.find(item => item.to === path || (item.children && item.children[path]));

        if (navigationItem && navigationItem.subItems && navigationItem.subItems.length > 0) {
            return navigationItem.subItems;
        }

        return [];
    }

    static updateBodyClassName(show) {
        let className;

        if (typeof window !== 'undefined') {
            className = show ? 'offcanvas-open' : '';

            document.body.className = className;
            document.documentElement.className = className;
        }
    }

    constructor(props) {
        super(props);

        this.toggleUserDropdown = this.toggleUserDropdown.bind(this);
        this.toggleDealerDropdown = this.toggleDealerDropdown.bind(this);
        this.toggleOffcanvasDealerDropdown = this.toggleOffcanvasDealerDropdown.bind(this);
        this.toggleOffcanvas = this.toggleOffcanvas.bind(this);
        this.setDealer = this.setDealer.bind(this);
        this.showCustomerServiceModal = this.showCustomerServiceModal.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { dealerId, dealerCode, displayToast, showOffcanvas } = this.props;

        if (dealerId && dealerCode) {
            if (dealerId && prevProps.dealer && dealerId !== prevProps.dealer.id) {
                displayToast(`Anläggning ändrad till ${dealerCode}`);
            }

            if (showOffcanvas !== prevProps.showOffcanvas) {
                HeaderContainer.updateBodyClassName(showOffcanvas);
            }
        }
    }

    setDealer(newDealerId) {
        const { showOffcanvas, changeDealer, dealerId, groupMembers } = this.props;

        // Hide mobileOffcanvas when selecting a dealer
        showOffcanvas(false);

        if (newDealerId !== dealerId) {
            changeDealer(newDealerId, groupMembers);
        }
    }

    mapToggleOffcanvas(items) {
        const { showOffcanvas } = this.props;

        return items.map(item => {
            let subItems;
            let onClick = typeof item.onClick === 'function' ? item.onClick : () => {};

            onClick = e => {
                showOffcanvas(false);
                item.onClick(e);
            };

            if (item.subItems && item.subItems.length > 0) {
                subItems = this.mapToggleOffcanvas(item.subItems);
            }

            return {
                ...item,
                onClick,
                subItems
            };
        });
    }

    showCustomerServiceModal(e) {
        const { showModal } = this.props;
        if (typeof e !== 'undefined') {
            e.preventDefault();
        }

        showModal('customerService', true, uiConstants.MODAL_ORIGIN_HEADER);
    }

    toggleUserDropdown() {
        const { showUserDropdown, userDropdown } = this.props;
        showUserDropdown(!userDropdown);
    }

    toggleDealerDropdown() {
        const { showDealerDropdown, dealerDropdown } = this.props;
        showDealerDropdown(!dealerDropdown);
    }

    toggleOffcanvasDealerDropdown() {
        const { showOffcanvasDealerDropdown, offcanvasDealerDropdown } = this.props;
        showOffcanvasDealerDropdown(!offcanvasDealerDropdown);
    }

    toggleOffcanvas(e) {
        const { offcanvas, showOffcanvasCollapse, showOffcanvas } = this.props;
        if (typeof e !== 'undefined') {
            e.preventDefault();
        }

        // Reset offcanvas collapse when closing Offcanvas
        if (offcanvas) {
            showOffcanvasCollapse(undefined, false);
        }

        showOffcanvas(!offcanvas);
    }

    render() {
        const {
            isAuthed,
            user,
            userDropdown,
            offcanvas,
            dealerDropdown,
            offcanvasDealerDropdown,
            offcanvasCollapse,
            showOffcanvasCollapse,
            logoClick
        } = this.props;

        return (
            <>
                <Header
                    toggleDealerDropdown={this.toggleDealerDropdown}
                    toggleUserDropdown={this.toggleUserDropdown}
                    setDealer={this.setDealer}
                    userDropdown={userDropdown}
                    dealerDropdown={dealerDropdown}
                    offcanvas={offcanvas}
                    logoClick={logoClick}
                    showCustomerServiceModal={this.showCustomerServiceModal}
                />

                <MobileHeader
                    toggleOffcanvas={this.toggleOffcanvas}
                    isAuthed={isAuthed}
                    offcanvas={offcanvas}
                    user={user}
                    setDealer={this.setDealer}
                    toggleDealerDropdown={this.toggleOffcanvasDealerDropdown}
                    dealerDropdown={offcanvasDealerDropdown}
                    logoClick={logoClick}
                    toggleOffcanvasCollapse={showOffcanvasCollapse}
                    offcanvasCollapse={offcanvasCollapse}
                />
                <SubHeader />
            </>
        );
    }
}

const mapStateToProps = state => {
    const { id: dealerId, code: dealerCode, groupMembers } = dealerSelectors.getDealerApiData(state) || {};

    return {
        dealerId,
        dealerCode,
        groupMembers,
        user: userSelectors.getUser(state),
        isAuthed: userSelectors.getIsAuthenticated(state),
        offcanvas: uiSelectors.getShowOffcanvas(state),
        dealerDropdown: uiSelectors.getShowDealerDropdown(state),
        userDropdown: uiSelectors.getShowUserDropdown(state),
        offcanvasDealerDropdown: uiSelectors.getShowOffcanvasDealerDropdown(state),
        offcanvasCollapse: uiSelectors.getOffcanvasCollapse(state)
    };
};

const mapDispatchToProps = {
    changeDealer: userActions.changeDealer,
    displayToast: uiActions.toast,
    showModal: uiActions.modal,
    showOffcanvas: uiActions.showOffcanvas,
    showDealerDropdown: uiActions.showDealerDropdown,
    showOffcanvasDealerDropdown: uiActions.showOffcanvasDealerDropdown,
    showUserDropdown: uiActions.showUserDropdown,
    showOffcanvasCollapse: uiActions.showOffcanvasCollapse,
    logoClick: generalActions.logo,
    navigationClick: generalActions.navigationClick
};

export default connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(HeaderContainer);
