import * as UI from './actionTypes';

export const defaultState = {
    modal: {},
    toast: {},
    actionPanelOpen: false,
    showPageLoader: false,
    showUserDropdown: false,
    showDealerDropdown: false,
    showOffcanvas: false,
    showOffcanvasDealerDropdown: false,
    offcanvasCollapse: {
        id: undefined,
        show: false
    },
    showInitialLoader: false,
    showSidePanel: false
};

export default function ui(state = defaultState, action) {
    switch (action.type) {
        case UI.MODAL:
            return {
                ...state,
                modal: {
                    open: action.toggle,
                    id: action.id,
                    origin: action.origin
                }
            };

        case UI.TOAST:
            return {
                ...state,
                toast: {
                    message: action.message,
                    timeout: action.timeout || 3000,
                    status: action.status,
                    timestamp: Date.now()
                }
            };

        case UI.ACTION_PANEL:
            return {
                ...state,
                actionPanelOpen: action.open
            };

        case UI.PAGELOADER:
            return {
                ...state,
                showPageLoader: action.show
            };

        case UI.DEALERDROPDOWN:
            return {
                ...state,
                showDealerDropdown: action.show
            };

        case UI.OFFCANVAS:
            return {
                ...state,
                showOffcanvas: action.show
            };

        case UI.OFFCANVAS_DEALERDROPDOWN:
            return {
                ...state,
                showOffcanvasDealerDropdown: action.show
            };

        case UI.USERDROPDOWN:
            return {
                ...state,
                showUserDropdown: action.show
            };

        case UI.OFFCANVAS_COLLAPSE:
            return {
                ...state,
                offcanvasCollapse: action.offcanvasCollapse
            };

        case UI.INITIAL_LOADER:
            return {
                ...state,
                showInitialLoader: action.show
            };

        case UI.SIDE_PANEL:
            return {
                ...state,
                showSidePanel: action.show
            };
        case UI.HIDE_EQUIPMENT_INFO_ALERT:
            return {
                ...state,
                hideEquipmentInfoAlert: action.hide
            };

        default:
            return state;
    }
}
