import React from 'react';

import PermissionsProvider from 'components/PermissionsProvider';
import NavigationItem from 'components/Navigation/NavigationItem';
import ComposeNavigationItems from './ComposeNavigationItems';

interface Props {
    className?: string;
    id?: string | boolean;
}

const OfferNavigationItems = React.memo(({ className, id }: Props) => (
    <ComposeNavigationItems origin="header">
        {({ onNavigationClick }) => (
            <React.Fragment>
                <React.Fragment>
                    <NavigationItem
                        to="/offer"
                        id="offer"
                        onClick={onNavigationClick}
                        className={className}
                        active={!!id}
                    >
                        {id ? 'Redigera fordon' : 'Lägg till fordon'}
                    </NavigationItem>
                    <PermissionsProvider action="update" target="equipment">
                        <NavigationItem
                            to="/offer/equipment"
                            id="offer-equipment"
                            onClick={onNavigationClick}
                            className={className}
                        >
                            Utrustningslistor
                        </NavigationItem>
                    </PermissionsProvider>
                </React.Fragment>
            </React.Fragment>
        )}
    </ComposeNavigationItems>
));

export default OfferNavigationItems;
