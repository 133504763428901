import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';

import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from '@bytbil/bootstrap-react';

import useHasActionPermission from 'core/hooks/useHasActionPermission';

import { actions as userActions } from 'state/user';

import { pulseClickUIElement } from 'core/pulse';

import { RootState } from 'state/types';

import LOGOUT from '../../graphql/logout';
import InitialsIcon from './InitialsIcon';
import UserMeta from './UserMeta';

interface Props {
    toggleUserDropDown: () => void;
    isUserDropDownOpen: boolean;
    showCustomerServiceModal: (e: React.SyntheticEvent) => void;
}

const UserDropdown = ({ toggleUserDropDown, isUserDropDownOpen, showCustomerServiceModal }: Props) => {
    const dispatch = useDispatch();

    const {
        user: { first_name: firstName, last_name: lastName }
    } = useSelector((state: RootState) => state.iua) || {};

    const [logoutUser] = useMutation(LOGOUT);

    const logout = async () => {
        await logoutUser();

        dispatch(userActions.deauthenticate());
    };

    const toggleAndReset = () => {
        toggleUserDropDown();
    };

    const logoutHandler = async () => {
        pulseClickUIElement('Log out');

        await logout();
    };

    return (
        <Dropdown className="user-dropdown" isOpen={isUserDropDownOpen} toggle={toggleAndReset} inNavbar>
            <DropdownToggle
                tag="a"
                tabIndex="0"
                className="nav-link"
                role="button"
                data-toggle="dropdown"
                onClick={() => pulseClickUIElement('Initials icon')}
            >
                <InitialsIcon firstName={firstName} lastName={lastName} />
            </DropdownToggle>
            <DropdownMenu className="dropdown-fixed-width" right>
                <UserMeta />

                <DropdownItem
                    name="your-ad-prices"
                    href="/dealer/pricing"
                    onClick={() => pulseClickUIElement('UserDropdown - Pricing')}
                >
                    Dina annonspriser
                </DropdownItem>
                <hr className="mx-3 my-2" />
                <DropdownItem name="news" href="/news" onClick={() => pulseClickUIElement('UserDropdown -  News')}>
                    Nyheter
                </DropdownItem>
                <DropdownItem
                    name="publish-ads"
                    href="https://www.blocket.se/for-foretag/fordonshandlare/publicering_av_annons"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => pulseClickUIElement('UserDropdown - Published ads')}
                >
                    Publicera annonser
                </DropdownItem>

                <DropdownItem
                    name="questions-and-answers"
                    href="https://blocket.zendesk.com/hc/sv/sections/360000547899-Fordonshandlare"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => pulseClickUIElement('UserDropdown - Questions and answers')}
                >
                    Frågor & svar
                </DropdownItem>
                <DropdownItem
                    name="terms-and-conditions"
                    href="https://www.blocket.se/om/villkor/anvandarvillkor-fordon-bytbil"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => pulseClickUIElement('UserDropdown - Terms and conditions')}
                >
                    Allmänna villkor
                </DropdownItem>
                <DropdownItem
                    name="contact-us"
                    onClick={e => {
                        showCustomerServiceModal(e);
                        pulseClickUIElement('UserDropdown - Contact us');
                    }}
                >
                    Kontakta oss
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                    name="settings"
                    href="/user/settings"
                    onClick={() => pulseClickUIElement('UserDropdown - Settings')}
                >
                    <i className="fa fa-cog mr-2" />
                    Inställningar
                </DropdownItem>

                {useHasActionPermission('update', 'dealer') && (
                    <DropdownItem
                        name="manage-users"
                        href="/user/manage-users"
                        onClick={() => pulseClickUIElement('UserDropdown - Manage Users')}
                    >
                        <i className="fa fa-user mr-2" />
                        Hantera användare
                    </DropdownItem>
                )}

                <DropdownItem name="logout" onClick={() => logoutHandler()}>
                    <i className="fa fa-sign-out mr-2" />
                    Logga ut
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

export default UserDropdown;
