/* eslint-disable no-nested-ternary */
import React from 'react';
import * as Sentry from '@sentry/react';

import { useSelector } from 'react-redux';

import { selectors as iuaSelectors } from 'state/iua';
import { selectors as userSelectors } from 'state/user';

import {
    HasIUALoggedInSession,
    NoActiveSession,
    IsAuthApiSuperUser,
    MainLayout,
    UserIsAuthedByAuthApiForceIUASignup
} from './AuthComponentSelector';

Sentry.init({
    dsn: 'https://ba050f2443304eb19a3fc25d3692ac75@o64130.ingest.sentry.io/5719813',

    // While you're testing, we recommend that you set replaysSessionSampleRate to 1.0.
    // This ensures that every user session will be sent to Sentry.
    // Once testing is complete, we recommend lowering this value in production
    replaysSessionSampleRate: 0.1,
    // We still recommend keeping replaysOnErrorSampleRate set to 1.0.
    replaysOnErrorSampleRate: 1.0,

    integrations: [new Sentry.Replay()]
});

const App = ({ context }) => {
    const isAuthenticated = useSelector(state => iuaSelectors.getIsAuthenticated(state));

    const isAuthAPIAuthenticated = useSelector(state => userSelectors.getIsAuthenticated(state));
    const user = useSelector(state => userSelectors.getUser(state));

    /**
     * If user is authenticated by auth api we force them to sign up to iua
     */
    if (isAuthAPIAuthenticated && !isAuthenticated) {
        return <UserIsAuthedByAuthApiForceIUASignup context={context} />;
    }

    /**
     * If user has an active iua session
     */
    if (isAuthenticated) {
        return <HasIUALoggedInSession context={context} />;
    }

    /**
     * If user has no active auth-api session
     */
    if (!isAuthAPIAuthenticated) {
        return <NoActiveSession context={context} />;
    }

    /**
     * User has a auth-api session and is a super user (legacy concept but still used by customer support)
     * verboten-api does not support this concept
     */
    if (typeof user !== 'undefined' && user.level > 1) {
        return <IsAuthApiSuperUser context={context} />;
    }

    /**
     * If any of the above conditions are not met, render the main layout
     */
    return <MainLayout context={context} user={user} />;
};

export default App;
