import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'state/types';

import { Navbar } from '@bytbil/bootstrap-react';
import classNames from 'classnames';

import DealerDropdown from 'components/DealerDropdown';
import UserDropdown from 'components/UserDropdown';
import Logotype from 'components/Logotype';

import HeaderNavigationItems from './Navigation/HeaderNavigationItems';
import ChatBot from './ChatBot';

interface Props {
    offcanvas: boolean;
    toggleDealerDropdown: () => void;
    toggleUserDropdown: () => void;
    setDealer: (dealerId: number) => void;

    userDropdown: boolean;
    dealerDropdown: boolean;

    logoClick: () => void;
    showCustomerServiceModal: (e: React.SyntheticEvent) => void;
}

const Header = ({
    offcanvas,
    toggleDealerDropdown,
    toggleUserDropdown,
    setDealer,

    userDropdown,
    dealerDropdown,

    logoClick,
    showCustomerServiceModal
}: Props) => {
    const { authenticated: isAuthenticated, dealersData } = useSelector((state: RootState) => state.iua) || {};

    const userHasMultipleDealersAccess = dealersData && dealersData.length > 1;

    const shouldRenderDealerDropdown = dealersData && dealersData.length > 1;

    return (
        <Navbar expand="lg" tag="header" className="bg-dark-alt px-0 header-main d-none d-xl-flex" dark>
            <div className="container d-flex justify-content-between w-100">
                <div className="d-flex align-items-center flex-row flex-grow">
                    <Logotype
                        className={offcanvas && userHasMultipleDealersAccess ? '' : 'header-logo-above'}
                        onClick={logoClick}
                    />
                    <div className="flex-grow">
                        <ul
                            className={classNames(
                                'd-flex flex-row navbar-nav ml-4',
                                !isAuthenticated ? 'justify-content-end' : 'd-none d-xl-flex'
                            )}
                        >
                            <HeaderNavigationItems />
                        </ul>
                    </div>
                </div>
                {isAuthenticated ? (
                    <div className="d-flex align-items-center">
                        <ul className="d-flex flex-row navbar-nav">
                            <li className="nav-item d-flex align-items-center">
                                <ChatBot />
                            </li>
                            {shouldRenderDealerDropdown && (
                                <li className={classNames('nav-item', 'align-self-center')}>
                                    <DealerDropdown
                                        isOpen={dealerDropdown}
                                        toggle={toggleDealerDropdown}
                                        setDealer={setDealer}
                                        align="right"
                                    />
                                </li>
                            )}

                            <li className="nav-item">
                                <UserDropdown
                                    isUserDropDownOpen={userDropdown}
                                    toggleUserDropDown={toggleUserDropdown}
                                    showCustomerServiceModal={showCustomerServiceModal}
                                />
                            </li>
                        </ul>
                    </div>
                ) : (
                    <div>Laddar...</div>
                )}
            </div>
        </Navbar>
    );
};

export default Header;
