export const MODAL = 'MODAL';
export const TOAST = 'TOAST';
export const ACTION_PANEL = 'ACTION_PANEL';
export const PAGELOADER = 'PAGELOADER';
export const OFFCANVAS = 'OFFCANVAS';
export const DEALERDROPDOWN = 'DEALERDROPDOWN';
export const USERDROPDOWN = 'USERDROPDOWN';
export const OFFCANVAS_DEALERDROPDOWN = 'OFFCANVAS_DEALERDROPDOWN';
export const OFFCANVAS_COLLAPSE = 'OFFCANVAS_COLLAPSE';
export const INITIAL_LOADER = 'INITIAL_LOADER';
export const DISMISS_SCHIBSTED_STICKY_FOOTER = 'DISMISS_SCHIBSTED_STICKY_FOOTER';
export const DISMISS_SCHIBSTED_HEADER = 'DISMISS_SCHIBSTED_HEADER';
export const SIDE_PANEL = 'SIDE_PANEL';
export const HIDE_EQUIPMENT_INFO_ALERT = 'HIDE_EQUIPMENT_INFO_ALERT';
